import { handleActions } from 'redux-actions'
import * as actions from './actions'
import { computeCenterAndZoomFromBounds } from './utils'

const PADDING_RATIO = 0.9

const DEFAULT_OPTIONS = {
  width: 8.75,
  height: 5.75,
  borderWidth: 0.25,
  cornerRadius: 0,
  frameStyle: 1,
}

const stateFromHash = (initialState) => {
  let h = {}
  if (!!window.location.hash) {
    try {
      h = JSON.parse(atob(window.location.hash.substring(1)))
    } catch (e) {
      // clear invalid hashes
      console.error(e)
      window.location.hash = ''
      return initialState
    }
  } else {
    return initialState
  }

  console.assert(typeof h.db !== 'object')

  return {
    ...initialState,
    bounds: h.bb,
    bearing: [h.bd || 0],
    options: {
      ...initialState.options,
      width: h.w,
      height: h.h,
      borderWidth: h.b,
      cornerRadius: h.r,
      frameStyle: h.f,
    },
  }
}

export default handleActions({
  [actions.setMap]: (state, { payload }) => {
    return {
      ...state,
      map: payload,
    }
  },

  [actions.setOptions]: (state, { payload }) => {
    // FIXME recompute bounds here?
    return {
      ...state,
      options: {
        ...state.options,
        ...payload,
      }
    }
  },

  [actions.setBounds]: (state, { payload: { bounds, outerBounds, bearing, computed } }) => {
    return {
      ...state,
      bounds,
      outerBounds,
      bearing: [bearing],
      computed,
    }
  },

  [actions.fitToBounds]: (state, { payload }) => {
    const {
      mapWidth,
      mapHeight,
      bounds,
      bearing = 0,
    } = payload

    const {
      center,
      zoom,
    } = computeCenterAndZoomFromBounds({
      map: state.map,
      mapWidth,
      mapHeight,
      bounds,
      paddingRatio: PADDING_RATIO,
    })

    return {
      ...state,
      bounds,
      center,
      zoom: [zoom],
      bearing: [bearing],
      options: {
        ...state.options,
        width: mapWidth,
        height: mapHeight,
      },
    }
  },

  [actions.setPreviewMode]: (state, { payload }) => {
    return {
      ...state,
      previewMode: payload,
    }
  },

  [actions.exportToSVG]: (state, { payload }) => {
    return {
      ...state,
      exportSvg: null,
      exportStatus: 'PENDING',
    }
  },

  [actions.exportToSVGComplete]: (state, { payload }) => {
    return {
      ...state,
      exportSvg: payload,
      exportStatus: 'DONE',
    }
  }

}, stateFromHash({
  map: null,
  bounds: [[-128.9588, 54.1868], [-59.8288, 18.9458]],
  center: [-94.3938, 38.6880],
  zoom: [3.31],
  bearing: [0],
  options: DEFAULT_OPTIONS,
  previewMode: false,
  exportSvg: null,
  exportStatus: 'DONE',
}))
