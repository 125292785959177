import * as paper from 'paper'
import { unite, outlineAndUnite, removeSmallArtifacts, generateLines } from './utils'
import { ElegantFrame, MaskFrame } from './frame'

const DEFAULT_OPTIONS = {
  majorRoadsWidth: 2,
  minorRoadsWidth: 2,
}

// TODO move these
const COLORS = {
  MAPLE: '#E4D1BA',
  MAPLE_LIGHT_ENGRAVE: '#D0AC80',
  MAPLE_DARK_ENGRAVE: '#444444',
  WALNUT: '#603913',
  CHERRY: '#A97C50',
}

function getGroup(name) {
  return paper.project.getItem({ match: item => item.name === name })
}

export default function render({
  bearing,
  offsetX,
  offsetY,
  frameOpts,
  inputSvg,
  options = {},
}) {
  options = { ...DEFAULT_OPTIONS, ...options }

  // TODO is there a way to make this scoped?
  window.paper = paper
  // FIXME - size for rotated bounding box
  paper.setup(new paper.Size(frameOpts.outerWidth, frameOpts.outerHeight))

  // parse SVG
  console.log(`Importing svg... ${inputSvg.length} bytes`)
  console.time('Import SVG')
  const projItem = paper.project.importSVG(inputSvg)
  console.timeEnd('Import SVG')

  const frame = new ElegantFrame(frameOpts)
  // const frame = new MaskFrame({ ...frameOpts, mask: unite(getGroup('border').children) })

  // FIXME account for rotation
  projItem.rotate(-bearing, [ frameOpts.outerWidth / 2, frameOpts.outerHeight / 2 ])
  projItem.translate([-offsetX, -offsetY])

  const baseLayerChildren = renderBaseLayer(frame, options)
  const landLayerChildren = renderLandLayer(frame, options)
  const roadLayerChildren = renderRoadLayer(frame, options)

  console.log('Generating output...')
  const outputProject = new paper.Project(new paper.Size(frame.width, frame.height))

  const baseLayer = new paper.Layer(baseLayerChildren)
  baseLayer.name = 'Base Layer'

  const landLayer = new paper.Layer(landLayerChildren)
  landLayer.name = 'Land Layer'

  const roadLayer = new paper.Layer(roadLayerChildren)
  roadLayer.name = 'Road Layer'

  outputProject.addLayer(baseLayer)
  outputProject.addLayer(landLayer)
  outputProject.addLayer(roadLayer)

  return outputProject.exportSVG({ asString: true })
}

function renderRoadLayer(frame, options) {
  // const borderGroup = getGroup('border')
  const minorRoadsGroup = getGroup('roads-minor')
  const majorRoadsGroup = getGroup('roads-major')
  const streets = getGroup('roads-residential')

  // roads
  console.log('Uniting major roads...')
  console.time('Major Roads')
  const majorRoadsUnited = outlineAndUnite(majorRoadsGroup.children, options.majorRoadsWidth)
  console.timeEnd('Major Roads')

  console.log('Uniting minor roads...')
  console.time('Minor Roads')
  const minorRoadsUnited = outlineAndUnite(minorRoadsGroup.children, options.minorRoadsWidth)
  console.timeEnd('Minor Roads')
  //
  // // // TODO options to render streets?
  // console.log('Uniting streets...')
  // console.time('Streets')
  // const streetsUnited = outlineAndUnite(streets.children, options.minorRoadsWidth)
  // console.timeEnd('Streets')

  console.log('Compositing road layer...')
  console.time('All Roads')
  const allRoadsUnited = removeSmallArtifacts(majorRoadsUnited
    .unite(minorRoadsUnited, { insert: false })
    // .unite(streetsUnited, { insert: false })
    .intersect(frame.getInnerCrop(), { insert: false })
    .unite(frame.getInnerFrame(), { insert: false }))
  console.timeEnd('All Roads')

  allRoadsUnited.fillColor = COLORS.CHERRY

  return [ allRoadsUnited ]
}

function renderLandLayer(frame, options) {
  const water = getGroup('water')
  const land = getGroup('land')
  const buildings = getGroup('buildings')
  const pedestrianWalkways = getGroup('roads-pedestrian')
  const footways = getGroup('footways')
  const parks = getGroup('parks')
  const streets = getGroup('roads-residential')
  const minorRoads = getGroup('roads-minor')
  const border = getGroup('border')
  const piers = getGroup('piers')

  console.log('Cropping land and water layer...')
  console.time('Land')
  const croppedLand = unite(land.children)
    .intersect(frame.getInnerCrop(), { insert: false })

  croppedLand.fillColor = COLORS.MAPLE

  // const piersUnited = unite(piers.children)
  //   .intersect(frame.getInnerCrop(), { insert: false })

  console.log(`Subtracting water: (${water.children.length} elements)`)
  const croppedLandMinusWater = water.children.reduce((land, water, i) => {
    let landMinusWater;
    console.log(`  #${i + 1}: area=${water.area}`)
    if (Math.abs(water.area) >= 10) {
      landMinusWater = land.subtract(water.intersect(frame.getInnerCrop(), { insert: false }), { insert: false })
    } else {
      landMinusWater = land
    }
    return landMinusWater
  }, croppedLand)

  // const croppedWater = unite(water.children)
  //   .intersect(frame.getInnerCrop(), { insert: false })
  //
  const landAndFrame = croppedLandMinusWater
    .unite(frame.getOuterFrame(), { insert: false })
    .subtract(frame.getHoles(), { insert: false })
  console.timeEnd('Land')

  // console.log('Cropping minor roads...')
  // console.time('Minor Roads')
  // minorRoads.children.forEach(path => {
  //   path.fillColor = null
  //   path.strokeColor = '#000000'
  //   path.strokeWidth = 0.5
  //   path.replaceWith(path.intersect(croppedLandMinusWater, { trace: false, insert: false }))
  // })
  // console.timeEnd('Minor Roads')

  console.log('Cropping streets...')
  console.time('Streets')
  streets.children.forEach(path => {
    path.fillColor = null
    path.strokeColor = '#000000'
    path.strokeWidth = 0.5
    path.replaceWith(path.intersect(croppedLandMinusWater, { trace: false, insert: false }))
  })
  console.timeEnd('Streets')

  // const maskedArea = unite(getGroup('border').children)
  //   .intersect(croppedLandMinusWater, { insert: false })

  // const engravedPaths = removeSmallArtifacts(outlineAndUnite(footways.children, 1, 'butt')
  //   .intersect(maskedArea, { insert: false }))
  //
  // engravedPaths.fillColor = COLORS.MAPLE_DARK_ENGRAVE
  // engravedPaths.strokeColor = null
  // engravedPaths.strokeWidth = 0

  // console.log('Cropping paths...')
  // console.time('Paths')
  // footways.children.forEach(path => {
  //   path.fillColor = null
  //   path.strokeColor = '#000000'
  //   path.strokeWidth = 0.5
  //   path.replaceWith(path.intersect(maskedArea, { trace: false, insert: false }))
  // })
  // console.timeEnd('Paths')

  // console.log('Cropping buildings...')
  // console.time('Buildings')
  // buildings.children.forEach(path => {
  //   // path.fillColor = COLORS.MAPLE_LIGHT_ENGRAVE
  //   // path.strokeColor = null
  //   // path.strokeWidth = 0
  //   path.fillColor = null
  //   path.strokeColor = COLORS.MAPLE_DARK_ENGRAVE
  //   path.strokeWidth = 0.5
  //   path.replaceWith(path.intersect(croppedLandMinusWater, { insert: false }))
  // })
  // console.timeEnd('Buildings')

  // console.log('Cropping lines...')
  // console.time('Lines')
  // const lines = generateLines(frame.width, frame.height, 2, 45)
  // const buildingFills = unite(buildings.children)
  // lines.children.forEach(path => {
  //   path.fillColor = null
  //   path.strokeColor = COLORS.MAPLE_DARK_ENGRAVE
  //   path.strokeWidth = 0.5
  //   path.replaceWith(path.intersect(buildingFills, { insert: false }))
  // })
  // console.timeEnd('Lines')
  // const buildingLines = lines.intersect(buildingFills, { trace: false, insert: false })

  // const buildingLines = lines.intersect(buildings, { trace: false, insert: false })

  // const walkways = removeSmallArtifacts(unite(pedestrianWalkways.children))
  //
  // const footwaysUnited = removeSmallArtifacts(outlineAndUnite(footways.children, 1))
  //   .intersect(frame.getInnerCrop(), { insert: false })

  // console.log('Cropping parks...')
  // console.time('Parks')
  // const parksUnited = unite(parks.children)
  //   .intersect(croppedLandMinusWater, { insert: false })
  //   .subtract(walkways, { insert: false })
  //   .subtract(footwaysUnited, { insert: false })
  // parksUnited.fillColor = COLORS.MAPLE_LIGHT_ENGRAVE
  // console.timeEnd('Parks')

  return [
    landAndFrame,
    // frame.getOuterFrame(),
    // water,
    // piersUnited,
    // minorRoads,
    streets,
    // removeSmallArtifacts(buildings),
    // footways,
    // parksUnited,
    // maskedArea
  ]
}

function renderBaseLayer(frame, options) {
  const base = frame.getBase().clone({ insert: false })
  base.fillColor = COLORS.WALNUT
  return [ base ]
}
