import React from 'react'
import { connect } from 'react-redux'
import Humanize from 'humanize-plus'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import Select from '@material-ui/core/Select'
import Assignment from '@material-ui/icons/Assignment'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as actions from '../modules/map/actions'

import './MapSidebar.css'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const CITIES = {
  BOSTON: {
    bounds: [[-71.12041, 42.37479], [-71.0366, 42.33409]],
    width: 8.75,
    height: 5.75,
  },
  SF: {
    bounds: [[-122.5336, 37.8257], [-122.3454, 37.6769]],
    width: 5.75,
    height: 5.75,
  },
  NYC: {
    bounds: [[-74.0445, 40.8126], [-73.9283, 40.6939]],
    width: 5.75,
    height: 7.75,
  }
}

const COLORS = {
  MAPLE: '#E4D1BA',
  MAPLE_LIGHT_ENGRAVE: '#D0AC80',
  MAPLE_DARK_ENGRAVE: '#444444',
  WALNUT: '#603913',
  CHERRY: '#A97C50',
}

const SIZES = [
  { value: 3.75, label: '3 3/4"' },
  { value: 4.75, label: '4 3/4"' },
  { value: 5, label: '5"' },
  { value: 5.75, label: '5 3/4"' },
  { value: 6, label: '6"' },
  { value: 6.75, label: '6 3/4"' },
  { value: 7.75, label: '7 3/4"' },
  { value: 8, label: '8"' },
  { value: 8.75, label: '8 3/4"' },
  { value: 9, label: '9"' },
  { value: 9.75, label: '9 3/4"' },
  { value: 10, label: '10"' },
  { value: 10.75, label: '10 3/4"' },
  { value: 11, label: '11"' },
  { value: 11.75, label: '11 3/4"' },
  { value: 12, label: '12"' },
  { value: 13, label: '13"' },
  { value: 14, label: '14"' },
  { value: 15, label: '15"' },
  { value: 16, label: '16"' },
  { value: 17, label: '17"' },
  { value: 18, label: '18"' },
  { value: 19, label: '19"' },
]

const BORDER_SIZES = [
  { value: 0, label: 'None' },
  { value: 0.125, label: '1/8"' },
  { value: 0.25, label: '1/4"' },
]

const CORNER_SIZES = [
  { value: 0, label: 'None' },
  { value: 0.25, label: '1/4"' },
  { value: 0.5, label: '1/2"' },
]

const FRAME_STYLES = [
  { value: 0, label: 'Simple' },
  { value: 1, label: 'Elegant' },
]

const WOOD_COLORS = [
  COLORS.MAPLE,
  COLORS.CHERRY,
  COLORS.WALNUT,
]

const ColorChips = ({ selectedColor, onSetColor }) => {
  return (
    <>
      {WOOD_COLORS.map(color => (
        <div
          key={color}
          onClick={() => onSetColor(color)}
          className={`MapBuilder-color-chip ${selectedColor === color ? 'selected' : ''}`}
          style={{ backgroundColor: color }} />
      ))}
    </>
  )
}

function ColorChipsPanel({ layers, onLayerColorChange }) {
  return (
    <>
      <div className='MapBuilder-leftright'>
        <div>Water</div>
        <div className='MapBuilder-leftright-right'>
          <ColorChips
            onSetColor={(color) => onLayerColorChange({ layer: 'water', color, })}
            selectedColor={layers.water.color}
          />
        </div>
      </div>
      <div className='MapBuilder-leftright'>
        <div>Land</div>
        <div className='MapBuilder-leftright-right'>
          <ColorChips
            onSetColor={(color) => onLayerColorChange({ layer: 'land', color, })}
            selectedColor={layers.land.color}
          />
        </div>
      </div>
      <div className='MapBuilder-leftright'>
        <div>Roads</div>
        <div className='MapBuilder-leftright-right'>
          <ColorChips
            onSetColor={(color) => onLayerColorChange({ layer: 'road', color, })}
            selectedColor={layers.road.color}
          />
        </div>
      </div>
    </>
  )
}

function MapSidebar(props) {
  const {
    bounds,
    bearing,
    layers,
    onLayerColorChange,
    options,
    previewMode,
    exportSvg,
    exportStatus,
    setOptions,
    setPreviewMode,
    setSnackbar,
    fitToBounds,
    exportToSVG,
  } = props

  const classes = useStyles()

  const zoomTo = (city) => () => {
    const coords = CITIES[city]
    fitToBounds({
      bounds: coords.bounds.slice(),
      mapWidth: coords.width,
      mapHeight: coords.height,
    })
  }

  const copyToClipboard = (svgStr) => {
    navigator.clipboard.writeText(svgStr).then(() => {
      setSnackbar(`Successfully copied SVG to clipboard (${Humanize.fileSize(svgStr.length)})`, 'success')
    }, (err) => {
      setSnackbar(`Failed to copy SVG to clipboard (${Humanize.fileSize(svgStr.length)})`, 'error')
      console.error(err)
    })
  }

  return (
    <div className='MapBuilder-options'>
      <h3>Jump To</h3>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={zoomTo('BOSTON')}>BOS</Button>
        <Button onClick={zoomTo('SF')}>SF</Button>
        <Button onClick={zoomTo('NYC')}>NYC</Button>
      </ButtonGroup>
      <hr />
      <h3>Customization Options</h3>
      <FormControl className={classes.formControl}>
        <InputLabel id="MapBuilder-options-width-label">Map Width</InputLabel>
        <Select
          native
          labelId="MapBuilder-options-width-label"
          id="MapBuilder-options-width"
          value={options.width}
          onChange={(e) => setOptions({ width: parseFloat(e.target.value) })}
        >
          {SIZES.map(({ value, label }) => (
            <option key={value} aria-label={label} value={value}>{label}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="MapBuilder-options-height-label">Map Height</InputLabel>
        <Select
          native
          labelId="MapBuilder-options-height-label"
          id="MapBuilder-options-height"
          value={options.height}
          onChange={(e) => setOptions({ height: parseFloat(e.target.value) })}
        >
          {SIZES.map(({ value, label }) => (
            <option key={value} aria-label={label} value={value}>{label}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="MapBuilder-options-bordersize-label">Border Width</InputLabel>
        <Select
          native
          labelId="MapBuilder-options-bordersize-label"
          id="MapBuilder-options-bordersize"
          value={options.borderWidth}
          onChange={(e) => setOptions({ borderWidth: parseFloat(e.target.value) })}
        >
          {BORDER_SIZES.map(({ value, label }) => (
            <option key={value} aria-label={label} value={value}>{label}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="MapBuilder-options-cornerradius-label">Corner Radius</InputLabel>
        <Select
          native
          labelId="MapBuilder-options-cornerradius-label"
          id="MapBuilder-options-cornerradius"
          value={options.cornerRadius}
          onChange={(e) => setOptions({ cornerRadius: parseFloat(e.target.value) })}
        >
          {CORNER_SIZES.map(({ value, label }) => (
            <option key={value} aria-label={label} value={value}>{label}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="MapBuilder-options-framestyle-label">Frame Style</InputLabel>
        <Select
          native
          labelId="MapBuilder-options-framestyle-label"
          id="MapBuilder-options-framestyle"
          value={options.frameStyle}
          onChange={(e) => setOptions({ frameStyle: parseInt(e.target.value) })}
        >
          {FRAME_STYLES.map(({ value, label }) => (
            <option key={value} aria-label={label} value={value}>{label}</option>
          ))}
        </Select>
      </FormControl>
      <hr/>
      <h3>
        <span style={{ marginRight: '8px' }}>Preview</span>
        <Switch
          checked={previewMode}
          onChange={(e) => setPreviewMode && setPreviewMode(e.target.checked)}
        />
      </h3>
      <ColorChipsPanel layers={layers} onLayerColorChange={onLayerColorChange} />
      <div className='MapBuilder-leftright'>
        <div>Engraved Parks</div>
        <div className='MapBuilder-leftright-right'>
          <Switch
            checked={layers.land_park.opacity === 1}
            onChange={(e) => {
              onLayerColorChange({
                layer: 'land_park',
                color: layers.land_park.color,
                opacity: e.target.checked ? 1 : 0
              })
              onLayerColorChange({
                layer: 'land_park_footways',
                color: '#000000',
                opacity: e.target.checked ? 1 : 0
              })
              onLayerColorChange({
                layer: 'land_park_pitches',
                color: layers.land.color,
                opacity: e.target.checked ? 1 : 0
              })
            }}
          />
        </div>
      </div>
      <div className='MapBuilder-leftright'>
        <div>Engraved Buildings</div>
        <div className='MapBuilder-leftright-right'>
          <Switch
            checked={layers.buildings.opacity === 1}
            onChange={(e) => onLayerColorChange({
              layer: 'buildings',
              color: layers.buildings.color,
              opacity: e.target.checked ? 1 : 0
            })}
          />
        </div>
      </div>
      <div className='MapBuilder-leftright'>
        <div>Engraved Streets</div>
        <div className='MapBuilder-leftright-right'>
          <Switch
            checked={layers.road_street.width === 1}
            onChange={(e) => onLayerColorChange({
              layer: 'road_street',
              color: e.target.checked ? COLORS.MAPLE_DARK_ENGRAVE : COLORS.CHERRY,
              width: e.target.checked ? 1 : 3,
            })}
          />
        </div>
      </div>
      <div style={{
        width: '100%',
        fontSize: '12px',
        fontStyle: 'italic',
        textAlign: 'center',
      }}>
        Note that the final design will differ slightly from this preview
      </div>
      <h3>Bounds</h3>
      {bounds && (
        <>
          <div className='MapBuilder-extent'>
            <div className='MapBuilder-extent-label'>Top Left</div>
            <div className='MapBuilder-extent-value'>
              {Humanize.intComma(bounds[0][0], 4)}, {Humanize.intComma(bounds[0][1], 4)}
            </div>
          </div>
          <div className='MapBuilder-extent'>
            <div className='MapBuilder-extent-label'>Bottom Right</div>
            <div className='MapBuilder-extent-value'>
              {Humanize.intComma(bounds[1][0], 4)}, {Humanize.intComma(bounds[1][1], 4)}
            </div>
          </div>
          <div className='MapBuilder-extent'>
            <div className='MapBuilder-extent-label'>Bearing</div>
            <div className='MapBuilder-extent-value'>
              {Humanize.toFixed(bearing[0], 2)} degrees
            </div>
          </div>
        </>
      )}
      <hr />
      <div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={exportStatus === 'PENDING'}
          onClick={() => exportToSVG()}
        >
          Export
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          disabled={exportStatus !== 'DONE' || exportSvg === null}
          style={{ marginLeft: '8px' }}
          onClick={() => copyToClipboard(exportSvg)}
        >
          {exportStatus === 'PENDING' ? (
            <span style={{ height: '24px' }}>
              <CircularProgress size={24} />
            </span>
          ) : <Assignment />}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    previewMode: state.map.previewMode,
    options: state.map.options,
    bounds: state.map.bounds,
    bearing: state.map.bearing,
    exportSvg: state.map.exportSvg,
    exportStatus: state.map.exportStatus
  }
}

const mapDispatchToProps = {
  setPreviewMode: actions.setPreviewMode,
  setOptions: actions.setOptions,
  fitToBounds: actions.fitToBounds,
  exportToSVG: actions.exportToSVG,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapSidebar)