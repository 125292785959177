import React from 'react'
import { connect } from 'react-redux'
import syncMove from '@mapbox/mapbox-gl-sync-move'
import ReactMapboxGl, {GeoJSONLayer, Layer} from 'react-mapbox-gl'
import * as actions from '../modules/map/actions'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import './Map.css'

import MapSidebar from './MapSidebar'
// import GTECH from '../modules/map/gtech.json'
// import bboxPolygon from '@turf/bbox-polygon'
// import mask from '@turf/mask'

const flattenLngLat = ({ lat, lng }) => [lng, lat]

const COLORS = {
  MAPLE: '#E4D1BA',
  MAPLE_LIGHT_ENGRAVE: '#D0AC80',
  MAPLE_DARK_ENGRAVE: '#444444',
  WALNUT: '#603913',
  CHERRY: '#A97C50',
}

const Map = ReactMapboxGl({
  accessToken: "pk.eyJ1IjoidHJldm9ycnVuZGVsbCIsImEiOiJja2dpcXV3MzAwMHY2MnhxdGgwdm1jMnhiIn0.3xL4_Ftx_TyXujR7KKiamg",
  pitchWithRotate: false,
  // comment these out to allow rotation
  // touchZoomRotate: false,
  // dragRotate: false,
})

const EDITABLE_LAYERS = [
  { id: 'land', type: 'background', group: 'land' },
  { id: 'national-park', type: 'fill', group: 'land_park' },
  { id: 'landuse', type: 'fill', group: 'land_park' },
  { id: 'aeroway-line', type: 'line', group: 'land_park' },
  { id: 'footways', type: 'line', group: 'land_park_footways' },
  { id: 'pitches', type: 'fill', group: 'land_park_pitches' },
  { id: 'water', type: 'fill', group: 'water' },
  { id: 'waterway', type: 'line', group: 'water' },
  { id: 'road-motorway-trunk', type: 'line', group: 'road' },
  { id: 'road-motorway-trunk-link', type: 'line', group: 'road' },
  { id: 'road-primary', type: 'line', group: 'road' },
  { id: 'road-primary-link', type: 'line', group: 'road' },
  { id: 'road-secondary-tertiary', type: 'line', group: 'road' },
  { id: 'road-secondary-tertiary-link', type: 'line', group: 'road' },
  { id: 'road-rail', type: 'line', group: 'road' },
  { id: 'road-street', type: 'line', group: 'road_street' },
  { id: 'building', type: 'line', group: 'buildings' },
]

class MapContainer extends React.Component {
  state = {
    layers: {
      water: {
        color: COLORS.WALNUT,
        opacity: 1,
      },
      land: {
        color: COLORS.MAPLE,
        opacity: 1,
      },
      land_park: {
        color: COLORS.MAPLE_LIGHT_ENGRAVE,
        opacity: 0,
      },
      land_park_footways: {
        color: COLORS.MAPLE_DARK_ENGRAVE,
        opacity: 0,
        width: 1,
      },
      land_park_pitches: {
        color: COLORS.MAPLE,
        opacity: 0,
      },
      road: {
        color: COLORS.CHERRY,
        opacity: 1,
        width: 3,
      },
      road_street: {
        color: COLORS.MAPLE_DARK_ENGRAVE,
        opacity: 1,
        width: 1,
      },
      buildings: {
        color: COLORS.MAPLE_DARK_ENGRAVE,
        opacity: 0,
        width: 1,
      }
    },
    snackbar: {
      message: null,
      severity: null,
      open: false,
    }
  }

  calculateBounds = () => {
    if (!this.map) {
      return
    }

    const [
      containerWidth,
      containerHeight,
    ] = this.map._containerDimensions()

    const {
      options
    } = this.props

    const W = options.width * 72
    const H = options.height * 72
    const B = options.borderWidth * 72 * 2 // double to get total border thickness
    const R = W / H
    const CR = options.cornerRadius * 72
    const cw = containerWidth
    const ch = containerHeight

    let w, h
    if (cw / ch > R) {
      h = ch * .9
      w = h * R
    } else {
      w = cw * .9
      h = w / R
    }

    const scale = w / W
    const dy = (ch-h) / 2
    const dx = (cw-w) / 2

    const zoom = this.map.getZoom()
    const center = this.map.getCenter()
    const pixelCenter = this.map.project(center)
    const rotatedRect = {
      tl: {
        x: pixelCenter.x - w / 2,
        y: pixelCenter.y - h / 2,
      },
      br: {
        x: pixelCenter.x + w / 2,
        y: pixelCenter.y + h / 2,
      },
    }

    const rotate = (p, o, theta) => {
      return {
        x: Math.cos(theta) * (p.x - o.x) - Math.sin(theta) * (p.y - o.y) + o.x,
        y: Math.sin(theta) * (p.x - o.x) + Math.cos(theta) * (p.y - o.y) + o.y,
      }
    }

    // FIXME how to clamp the bearing to prevent the rectangle from being smaller, is this right??
    const mod = (a, n) => ((a % n) + n) % n
    const bearing = this.map.getBearing()
    const bdeg = mod(bearing+ 90, 180) - 90
    const brad = bdeg * Math.PI / 180
    const unrotatedRect = {
      tl: rotate(rotatedRect.tl, pixelCenter, -brad),
      br: rotate(rotatedRect.br, pixelCenter, -brad),
    }

    const bounds = [
      flattenLngLat(this.map.unproject({ x: unrotatedRect.tl.x, y: unrotatedRect.tl.y })),
      flattenLngLat(this.map.unproject({ x: unrotatedRect.br.x, y: unrotatedRect.br.y }))
    ]

    const a = Math.cos(Math.abs(brad)) * W
    const b = Math.sin(Math.abs(brad)) * W
    const c = Math.cos(Math.abs(brad)) * H
    const d = Math.sin(Math.abs(brad)) * H
    const W2 = a + d
    const H2 = b + c

    const w2 = W2 * scale
    const h2 = H2 * scale
    const outerRect = {
      tl: {
        x: pixelCenter.x - w2 / 2,
        y: pixelCenter.y - h2 / 2,
      },
      br: {
        x: pixelCenter.x + w2 / 2,
        y: pixelCenter.y + h2 / 2,
      },
    }
    const outerBounds = [
      flattenLngLat(this.map.unproject(rotate(outerRect.tl, pixelCenter, -brad))),
      flattenLngLat(this.map.unproject(rotate(outerRect.br, pixelCenter, -brad)))
    ]

    return {
      bounds,
      outerBounds,
      bearing,
      center: flattenLngLat(center),
      zoom,
      target: {
        width: w,
        height: h,
        offsetX: dx,
        offsetY: dy,
        scale: scale,
        radius: CR * scale,
        shadow: Math.max(dy, dx) * 2,
      },
      innerTarget: {
        visible: B > 0,
        width: (W - B) * scale,
        height: (H - B) * scale,
        offsetX: dx + (B / 2) * scale,
        offsetY: dy + (B / 2) * scale,
        shadow: B / 2 * scale,
        radius: CR > 0 ? Math.max(CR * scale - (B / 2 * scale), 1) : 0,
      },
      outerTarget: {
        visible: false, // bdeg !== 0, TODO remove this code completely?
        width: w2,
        height: h2,
        offsetX: outerRect.tl.x,
        offsetY: outerRect.tl.y,
        rotation: -bdeg,
      },
      computed: {
        outerWidth: W2,
        outerHeight: H2,
        outerOffsetX: (W2 - W) / 2,
        outerOffsetY: (H2 - H) / 2,
      }
    }
  }

  setSnackbar = (message, severity) => {
    this.setState({
      snackbar: {
        message,
        severity,
        open: true,
      }
    })
  }

  closeSnackbar = () => {
    this.setState((state) => ({
      snackbar: {
        ...state.snackbar,
        open: false,
      }
    }))
  }

  handleStyleLoad = (map) => {
    // debug add map to window
    this.map = window.map = map
    this.props.setMap(map)

    const {
      bounds,
      bearing,
      options,
    } = this.props

    this.props.fitToBounds({
      bounds,
      bearing: bearing[0],
      mapWidth: options.width,
      mapHeight: options.height,
    })

    if (this.map2) {
      syncMove(map, this.map2)
    }
  }

  handlePreviewStyleLoad = (map) => {
    // debug add map2 to window
    this.map2 = window.map2 = map

    if (this.map) {
      syncMove(this.map, map)
    }

    // set initial layer colors
    if (this.map2.getLayer('water')) {
      EDITABLE_LAYERS.forEach(({ id, type, group }) => {
        Object.entries(this.state.layers[group]).forEach(([field, value]) => {
          this.map2.setPaintProperty(id, `${type}-${field}`, value)
        })
      })
    } else {
      console.error('layers not loaded')
    }
  }

  handleSetLayerColor = ({ layer, color, width, opacity }) => {
    this.setState((state) => {
      color = color !== undefined ? color : state.layers[layer].color
      width = width !== undefined ? width : state.layers[layer].width
      opacity = opacity !== undefined ? opacity : state.layers[layer].opacity
      return {
        layers: {
          ...state.layers,
          [layer]: {
            color,
            opacity,
            width,
          }
        }
      }
    })
  }

  /*
   * After the map resizes, we need to reposition our viewport to the proper bounds
   */
  handleResize = () => {
    if (!this.map) {
      return
    }

    const {
      bounds,
      bearing,
      options,
    } = this.props

    this.props.fitToBounds({
      bounds,
      bearing: bearing[0],
      mapWidth: options.width,
      mapHeight: options.height,
    })
  }

  curriedEventFilter = (handler, previewMode) => {
    return (e) => {
      this.props.previewMode === previewMode && handler(e)
    }
  }

  updateBounds = () => {
    this.props.setBounds(this.calculateBounds())
  }

  handleRotateEnd = this.curriedEventFilter(this.updateBounds, false)
  handlePreviewRotateEnd = this.curriedEventFilter(this.updateBounds, true)
  handleZoomEnd = this.curriedEventFilter(this.updateBounds, false)
  handlePreviewZoomEnd = this.curriedEventFilter(this.updateBounds, true)

  renderOverlay() {
    if (!this.map) {
      return
    }

    const {
      options,
      previewMode,
    } = this.props

    const {
      layers,
    } = this.state

    const {
      target,
      innerTarget,
      outerTarget,
    } = this.calculateBounds()

    const boxShadowColor = previewMode
      ? 'rgba(240,240,240,1.0)'
      : 'rgba(255,255,255,0.7)'

    const innerBoxShadowColor = previewMode
      ? (options.frameStyle === 1 ? layers.land.color : layers.road.color)
      : 'rgba(255,0,0,0.2)'

    return (
      <>
        <div className='Map-overlay' style={{
          width: `${target.width}px`,
          height: `${target.height}px`,
          top: `${target.offsetY}px`,
          left: `${target.offsetX}px`,
          boxShadow: `0 0 0 ${target.shadow}px ${boxShadowColor}`,
          clip: `rect(${-target.offsetY}px, ${target.width+target.offsetX}px, ${target.height+target.offsetY}px, ${-target.offsetX}px)`,
          borderRadius: target.radius,
          border: previewMode ? `1px solid ${innerBoxShadowColor}` : undefined,
        }} />
        {!!outerTarget.visible && (
          <div className='Map-bbox-bearing' style={{
            width: `${outerTarget.width}px`,
            height: `${outerTarget.height}px`,
            top: `${outerTarget.offsetY}px`,
            left: `${outerTarget.offsetX}px`,
            transform: `rotate(${outerTarget.rotation}deg)`,
          }} />
        )}
        {!!innerTarget.visible && (
          <>
            <div key='safezone' className='Map-safezone' style={{
              width: `${innerTarget.width}px`,
              height: `${innerTarget.height}px`,
              top: `${innerTarget.offsetY}px`,
              left: `${innerTarget.offsetX}px`,
              boxShadow: `0 0 0 ${innerTarget.shadow}px ${innerBoxShadowColor}`,
              borderRadius: innerTarget.radius,
              border: previewMode ? `1px solid ${innerBoxShadowColor}` : undefined,
            }} />
            {!!previewMode && options.frameStyle === 1 && (
              <div key='safezone-border' className='Map-safezone-border' style={{
                width: `${innerTarget.width}px`,
                height: `${innerTarget.height}px`,
                top: `${innerTarget.offsetY}px`,
                left: `${innerTarget.offsetX}px`,
                borderRadius: innerTarget.radius,
                outline: `${8 * target.scale}px solid ${layers.road.color}`,
                outlineOffset: `${-4 * target.scale}px`,
              }} />
            )}
          </>
        )}
      </>
    )
  }

  // renderMask() {
  //   if (!this.map) {
  //     return
  //   }
  //
  //   const extents = this.map.getBounds().toArray().flat()
  //   const extentPoly = bboxPolygon(extents)
  //   const masked = mask(GTECH, extentPoly)
  //
  //   return (
  //     <>
  //       <GeoJSONLayer
  //         id='gtech'
  //         data={GTECH}
  //         type='fill'
  //         fillPaint={{
  //           [`fill-color`]: '#0000FF',
  //           [`fill-opacity`]: 0.5,
  //         }} />
  //       {/*<GeoJSONLayer*/}
  //       {/*  id='gtech-line'*/}
  //       {/*  data={GTECH}*/}
  //       {/*  type='line'*/}
  //       {/*  linePaint={{*/}
  //       {/*    [`line-color`]: this.state.layers.road.color,*/}
  //       {/*    [`line-opacity`]: 1,*/}
  //       {/*    [`line-width`]: 5,*/}
  //       {/*  }} />*/}
  //     </>
  //   )
  // }

  render() {
    const {
      initialCenter,
      initialZoom,
      previewMode,
      bearing,
    } = this.props

    const {
      layers,
      snackbar,
    } = this.state

    return (
      <div className='Map-outer-container'>
        <div className='Map-container'>
          <Map
            // eslint-disable-next-line
            style='mapbox://styles/mapbox/streets-v11'
            onStyleLoad={this.handleStyleLoad}
            onResize={this.handleResize}
            onRotateEnd={this.handleRotateEnd}
            onDragEnd={this.updateBounds}
            onZoomEnd={this.handleZoomEnd}
            onBoxZoomEnd={this.updateBounds}
            onDblClick={this.updateBounds}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
            center={initialCenter}
            zoom={initialZoom}
            bearing={bearing}
            movingMethod='jumpTo'
          />
          <Map
            // eslint-disable-next-line
            style='mapbox://styles/trevorrundell/ckgqtbmed5v6n19pml9i719py' // land, water, roads and parks
            onStyleLoad={this.handlePreviewStyleLoad}
            containerStyle={{
              width: '100%',
              height: '100%',
              visibility: previewMode ? 'visible' : 'hidden',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            onResize={this.handleResize}
            onRotateEnd={this.handlePreviewRotateEnd}
            onDragEnd={this.updateBounds}
            onZoomEnd={this.handlePreviewZoomEnd}
            onBoxZoomEnd={this.updateBounds}
            onDblClick={this.updateBounds}
            center={initialCenter}
            zoom={initialZoom}
            bearing={bearing}
            movingMethod='jumpTo'
          >
            {EDITABLE_LAYERS.map(({ id, type, group }) => (
              <Layer
                key={id}
                id={id}
                type={type}
                paint={{
                  [`${type}-color`]: layers[group].color,
                  [`${type}-opacity`]: layers[group].opacity,
                  [`${type}-width`]: layers[group].width,
                }} />
            ))}
          </Map>
          {this.renderOverlay()}
        </div>
        <MapSidebar
          layers={layers}
          onLayerColorChange={this.handleSetLayerColor}
          setSnackbar={this.setSnackbar}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
          open={!!snackbar.open}
          onClose={() => this.closeSnackbar()}
        >
          <Alert onClose={() => this.closeSnackbar()} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    previewMode: state.map.previewMode,
    options: state.map.options,
    bounds: state.map.bounds,
    bearing: state.map.bearing,
    initialCenter: state.map.center,
    initialZoom: state.map.zoom,
  }
}

const mapDispatchToProps = {
  setMap: actions.setMap,
  setBounds: actions.setBounds,
  fitToBounds: actions.fitToBounds,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer)
