import {
  createStore as _createStore,
  applyMiddleware
} from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import rootReducer from './modules/reducer'
import rootEpic from './modules/epic'

export function createStore() {
  const epicMiddleware = createEpicMiddleware()
  const store = _createStore(rootReducer, applyMiddleware(epicMiddleware))
  epicMiddleware.run(rootEpic)
  return store
}