import { fitBounds } from '@math.gl/web-mercator'

export const computeCenterAndZoomFromBounds = ({
  map,
  mapWidth,
  mapHeight,
  bounds,
  paddingRatio = 0.9,
}) => {
  const [
    containerWidth,
    containerHeight,
  ] = map._containerDimensions()
  const containerRatio = containerWidth / containerHeight
  const mapRatio = mapWidth / mapHeight

  let targetHeight, targetWidth
  if (containerRatio > mapRatio) { // height constrained
    targetHeight = containerHeight * paddingRatio
    targetWidth = targetHeight * mapRatio
  } else { // width constrained
    targetWidth = containerWidth * paddingRatio
    targetHeight = targetWidth / mapRatio
  }

  const padding = {
    left: (containerWidth - targetWidth) / 2,
    right: (containerWidth - targetWidth) / 2,
    top: (containerHeight - targetHeight) / 2,
    bottom: (containerHeight - targetHeight) / 2,
  }

  const {
    latitude,
    longitude,
    zoom,
  } = fitBounds({
    width: containerWidth,
    height: containerHeight,
    bounds,
    padding,
  })

  return {
    center: [longitude, latitude],
    zoom,
  }
}