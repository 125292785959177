import * as paper from 'paper'
import { PaperOffset } from 'paperjs-offset'
import { unite } from './utils'

function makeRect(x, y, w, h, r) {
  return new paper.Path.Rectangle(new paper.Rectangle(x, y, w, h), r, { insert: false })
}

function makeCircle(x, y, r) {
  return new paper.Path.Circle([ x, y ], r, { insert: false })
}

export class ElegantFrame {
  constructor({ width, height, edgeThickness, frameThickness, holeRadius, capRadius }) {
    this.width = width
    this.height = height

    // base rectange, the size of the entire map
    this.base = makeRect(0, 0, width, height)

    const innerCropRect = makeRect(edgeThickness, edgeThickness, width - edgeThickness * 2, height - edgeThickness * 2)

    // cut holes for alignment dowels
    this.holes = unite([
      makeCircle(edgeThickness, edgeThickness, holeRadius),
      makeCircle(width - edgeThickness, edgeThickness, holeRadius),
      makeCircle(width - edgeThickness, height - edgeThickness, holeRadius),
      makeCircle(edgeThickness, height - edgeThickness, holeRadius)
    ])

    // engraved pockets for alignment dowels (add extra .5px for fit)
    this.pockets = unite([
      makeCircle(edgeThickness, edgeThickness, holeRadius + .25),
      makeCircle(width - edgeThickness, edgeThickness, holeRadius + .25),
      makeCircle(width - edgeThickness, height - edgeThickness, holeRadius + .25),
      makeCircle(edgeThickness, height - edgeThickness, holeRadius + .25)
    ])

    // extra thickness around alignment dowel holes to prevent cracks
    this.caps = unite([
      makeCircle(edgeThickness, edgeThickness, capRadius),
      makeCircle(width - edgeThickness, edgeThickness, capRadius),
      makeCircle(width - edgeThickness, height - edgeThickness, capRadius),
      makeCircle(edgeThickness, height - edgeThickness, capRadius)
    ])

    // inner frame on the top layer
    this.innerFrame = PaperOffset.offsetStroke(innerCropRect, frameThickness / 2, { cap: 'round', insert: false } )
      .unite(this.caps, { insert: false })

    // area inside of the inner frame
    this.innerCrop = innerCropRect.subtract(this.innerFrame, { insert: false })

    // empty edge outside of (and including) the inner frame
    this.outerFrame = this.base.subtract(innerCropRect, { insert: false })
      .unite(this.innerFrame, { insert: false })
  }

  getBase = () => {
    return this.base
  }

  getInnerFrame = () => {
    return this.innerFrame
  }

  getOuterFrame = () => {
    return this.outerFrame
  }

  getInnerCrop = () => {
    return this.innerCrop
  }

  getHoles = () => {
    return this.holes
  }

  getPockets = () => {
    return this.pockets
  }
}

export class MaskFrame {
  constructor({ width, height, frameThickness, mask }) {
    this.width = width
    this.height = height

    // base rectange, the size of the entire map
    this.base = makeRect(0, 0, width, height)

    const maskedArea = this.base.intersect(mask, { insert: false })

    // inner frame on the top layer
    this.innerFrame = unite(maskedArea.children.map((path) => {
      return PaperOffset.offsetStroke(path, frameThickness, { insert: false } )
        .subtract(path, { insert: false })
    }))

    // this.innerFrame = PaperOffset.offsetStroke(maskedArea, frameThickness, { insert: false } )
    //   .subtract(maskedArea, { insert: false })

    // area inside of the inner frame
    this.innerCrop = maskedArea

    // empty edge outside of (and including) the inner frame
    this.outerFrame = this.base.subtract(maskedArea, { insert: false })
  }

  getBase = () => {
    return this.base
  }

  getInnerFrame = () => {
    return this.innerFrame
  }

  getOuterFrame = () => {
    return this.outerFrame
  }

  getInnerCrop = () => {
    return this.innerCrop
  }

  getHoles = () => {
    return new paper.Path([], { insert: false })
  }

  getPockets = () => {
    return new paper.Path([], { insert: false })
  }
}