export const majorRoads = (bbox) => (`
[out:json][timeout:60];
(
  // way["railway"](${bbox});
  way["highway"="motorway"](${bbox});
  way["highway"="motorway_link"](${bbox});
  way["highway"="trunk"](${bbox});
  way["highway"="trunk_link"](${bbox});
  way["highway"="primary"](${bbox});
  way["highway"="primary_link"](${bbox});
);
out body;
>;
out skel qt;
`)

export const minorRoads = (bbox) => (`
[out:json][timeout:60];
(
  way["highway"="secondary"](${bbox});
  way["highway"="secondary_link"](${bbox});
  way["highway"="tertiary"](${bbox});
  way["highway"="tertiary_link"](${bbox});
);
out body;
>;
out skel qt;
`)

export const majorLinks = (bbox) => (`
[out:json][timeout:60];
(
  way["highway"="motorway_link"](${bbox});
  way["highway"="trunk_link"](${bbox});
  way["highway"="primary_link"](${bbox});
);
out body;
>;
out skel qt;
`)

export const minorLinks = (bbox) => (`
[out:json][timeout:60];
(
  way["highway"="secondary_link"](${bbox});
  way["highway"="tertiary_link"](${bbox});
);
out body;
>;
out skel qt;
`)

export const residentialRoads = (bbox) => (`
[out:json][timeout:60];
(
  way["highway"="living_street"](${bbox});
  way["highway"="residential"](${bbox});
  way["highway"="unclassified"](${bbox});
  way["highway"="pedestrian"](if:!is_closed())(${bbox});
  way["highway"="service"][name](${bbox});
  //way["highway"="service"](${bbox});
);
out body;
>;
out skel qt;
`)

export const pedestrianRoads = (bbox) => (`
[out:json][timeout:60];
(
  way["highway"="pedestrian"](${bbox});
  relation["highway"="pedestrian"](${bbox});
);
out body;
>;
out skel qt;
`)

export const railways = (bbox) => (`
[out:json][timeout:60];
(
  way["railway"](${bbox});
);
out body;
>;
out skel qt;
`)

export const footways = (bbox) => (`
[out:json][timeout:60];
(
  // query part for: “highway=*”
  (
    way[highway=footway](${bbox});
    way[highway=service](${bbox});
    way[highway=steps](${bbox});
    way[highway=path](${bbox});
  );
  -
  (
    way[footway=sidewalk](${bbox});
    way[tunnel](${bbox});
    way[building](${bbox});
    way[crossing](${bbox});
    way[highway=service][name](${bbox});
  );
);
out body;
>;
out skel qt;
`)

export const footBridges = (bbox) => (`
[out:json][timeout:60];
(
  way[highway=footway][bridge=yes](${bbox});
);
out body;
>;
out skel qt;
`)

export const water = (bbox) => (`
[out:json][timeout:60];
(
  (
    way[natural=water](${bbox});
    way[water=river](${bbox});
    way[waterway=riverbank](${bbox});
    relation[natural=water](${bbox});
    relation[water=river](${bbox});
    relation[waterway=riverbank](${bbox});
  );
  -
  (
    way[water=pool](${bbox});
    relation[water=pool](${bbox});
  );
);
out body;
>;
out skel qt;
`)

export const parks = (bbox) => (`
[out:json][timeout:60];
(
  way[leisure=park](${bbox});
  way[leisure=pitch](${bbox});
  // way[leisure=nature_reserve](${bbox});
  // way[leisure=playground](${bbox});
  // way[landuse=recreation_ground](${bbox});
  // way[landuse=cemetery](${bbox});
  relation[leisure=park](${bbox});
  relation[leisure=pitch](${bbox});
  // relation[leisure=playground](${bbox});
  // relation[leisure=nature_reserve](${bbox});
  // relation[landuse=recreation_ground](${bbox});
  // relation[landuse=cemetery](${bbox});
);
out body;
>;
out skel qt;
`)

export const buildings = (bbox) => (`
[out:json][timeout:60];
(
  way[building](${bbox});
  relation[building](${bbox});
);
out body;
>;
out skel qt;
`)

export const piers = (bbox) => (`
[out:json][timeout:60];
(
  way[man_made=pier](if:is_closed())(${bbox});
);
out body;
>;
out skel qt;
`)