import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ExploreIcon from '@material-ui/icons/Explore'
import Map from '../Map'

import './App.css'

function App() {
  return (
    <div className="App">
      <AppBar position='relative'>
        <Toolbar>
          <ExploreIcon className='App-header-icon' />
          <h3>Cartographer (Beta)</h3>
        </Toolbar>
      </AppBar>
      <Map />
    </div>
  )
}

export default App
