import * as paper from 'paper'
import { PaperOffset } from 'paperjs-offset'

export function simpleOutlineStroke(path, offset, cap = 'round') {
  const normal = path.getNormalAt(0)
  const p0 = path.firstSegment.getPoint().add(normal.multiply(offset))
  const p1 = path.lastSegment.getPoint().add(normal.multiply(offset))
  const p2 = path.lastSegment.getPoint().add(normal.multiply(-offset))
  const p3 = path.firstSegment.getPoint().add(normal.multiply(-offset))

  // draw outlined path
  const offsetPath = new paper.Path({ insert: false })
  if (cap === 'round') {
    offsetPath.moveTo(p0)
    offsetPath.lineTo(p1)
    offsetPath.arcTo(p2, true)
    offsetPath.lineTo(p3)
    offsetPath.arcTo(p0, true)
    offsetPath.closePath()
  } else if (cap === 'butt') {
    offsetPath.moveTo(p0)
    offsetPath.lineTo(p1)
    offsetPath.lineTo(p2)
    offsetPath.lineTo(p3)
    offsetPath.lineTo(p0)
    offsetPath.closePath()
  } else {
    throw new Error('Unsupported line cap type: ' + cap)
  }

  // copy path styles
  offsetPath.strokeWidth = 0;
  offsetPath.fillColor = path.strokeColor;
  offsetPath.shadowBlur = path.shadowBlur;
  offsetPath.shadowColor = path.shadowColor;
  offsetPath.shadowOffset = path.shadowOffset;

  return offsetPath
}

export function generateLines(width, height, spacing=1.5, angle=45) {
  const lines = []
  for (let i = 0; i < (width * 2) / spacing; i++) {
    const line = new paper.Path([new paper.Point(i * spacing, 0), new paper.Point(i * spacing, height * 2)])
    line.fillColor = null
    line.strokeColor = '#666666'
    line.strokeWidth = 0.5
    lines.push(line)
  }

  const group = new paper.Group({ children: lines }, { insert: false })
  group.translate(new paper.Point(-width / 2, -height / 2))
  group.rotate(angle, new paper.Point(width / 2, height / 2))

  return group
}

export function removeSmallArtifacts(compoundPath, threshold = 1) {
  if (compoundPath instanceof paper.CompoundPath) {
    compoundPath.children.concat().forEach(path => {
      if (Math.abs(path.area) < threshold) {
        path.remove()
      }
    })
  }
  return compoundPath
}

export function unite(paths, batchSize = 20) {
  if (paths.length === 0) {
    return new paper.Path([], { insert: false })
  }

  if (paths.length > batchSize) {
    const batches = []
    for (let i = 0; i < paths.length; i += batchSize) {
      batches.push(unite(paths.slice(i, i + batchSize)))
    }
    return unite(batches)
  } else {
    return paths.reduce((united, path) => united.unite(path, { insert: false }))
  }
}

export function outlineAndUnite(paths, strokeWidth, cap = 'round') {
  const outlinedPaths = paths
    .map((path, i, arr) => {
      let offsetPath
      if (path.curves.length === 1 && path.curves[0].isStraight()) {
        offsetPath = simpleOutlineStroke(path, strokeWidth / 2, cap)
      } else {
        offsetPath = PaperOffset.offsetStroke(path, strokeWidth / 2, { cap, join: 'round', insert: false } )
      }

      if (Math.abs(offsetPath.area) < 1) {
        return null
      } else {
        return offsetPath
      }
    })
    .filter(path => path !== null)

  return removeSmallArtifacts(unite(outlinedPaths))
}

