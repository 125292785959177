import osmtogeojson from 'osmtogeojson'

// const OSM_QUERY_ENDPOINT = 'http://localhost:8082/'
const OSM_QUERY_ENDPOINT = 'https://overpass.lanternmaps.com/api/interpreter'
// const OSM_QUERY_ENDPOINT = 'https://overpass-api.de/api/interpreter'

export const fetchGeoJsonLayer = (query) => {
  return _fetchGeoJsonLayer(query)
    .then((resp) => resp.json())
    .then((data) => osmtogeojson(data))
}

const _fetchGeoJsonLayer = (query) => {
  return fetch(OSM_QUERY_ENDPOINT, { method: 'POST', body: query })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp)
      } else {
        console.error('Failed query...\n', query)
        console.log('Trying again in 5s...')
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(_fetchGeoJsonLayer(query))
          }, 5000)
        })
      }
    })
}

export const fetchLandGeoJson = ({x, y}) => {
  const lat = y >= 0 ? `${y}N` : `${-y}S`
  const lng = x >= 0 ? `${x}E` : `${-x}W`
  const filename = `${lat}${lng}.json`
  return fetch(`https://lanternmaps-land-polygons.s3.amazonaws.com/${filename}`)
    .then(resp => resp.text())
    .then(data => data.split('\n')
      .filter(line => !!line)
      .map(line => JSON.parse(line)))
}

export const waitForAvailableSlot = () => {
  // FIXME hack to avoid making extra queries locally
  if (true || OSM_QUERY_ENDPOINT.startsWith('http://localhost')) {
    return Promise.resolve()
  }

  return fetch('https://overpass-api.de/api/status')
      .then((resp) => resp.text())
      .then((body) => {
        if (body.indexOf('available now') !== -1) {
          console.log('Ready, going')
          return Promise.resolve()
        } else {
          const waitTime = body.split('\n')
            .filter(line => line.startsWith('Slot available after'))
            .map(line => parseInt(/in ([0-9]+) seconds?/.exec(line)[1]))
            .sort()
            .reverse()[0] + 5
          console.log('Not ready, waiting...', waitTime)
          return new Promise((resolve) => {
            setTimeout(() => {
              console.log('Trying again...')
              resolve(waitForAvailableSlot())
            }, waitTime * 1000)
          })
        }
      })
}