import {combineEpics, ofType} from 'redux-observable'
import {EMPTY, from} from 'rxjs'
import {map, mergeMap} from 'rxjs/operators'
import geojson2svg from 'geojson2svg'
import proj4 from 'proj4'
import { reproject } from 'reproject'
import * as Humanize from 'humanize-plus'
import * as OSMQueries from './osmQueries'
import * as actions from './actions'
import * as api from './api'
import render from '../render/render'

const updateBoundsEpic = (action$, state$) => action$.pipe(
  ofType(actions.setBounds, actions.setOptions),
  mergeMap(({ type, payload }) => {
    const state = state$.value.map
    console.log('Setting bounds to: ' + state.bounds, type)

    console.assert(typeof state.bearing[0] !== 'object', 'pushState includes array!')

    const hashState = {
      'w': state.options.width,
      'h': state.options.height,
      'b': state.options.borderWidth,
      'r': state.options.cornerRadius,
      'f': state.options.frameStyle,
      'bb': state.bounds,
      'bd': state.bearing[0],
    }
    window.history.pushState({}, '', `#${btoa(JSON.stringify(hashState))}`)

    return EMPTY
  })
)

const exportEpic = (action$, state$) => action$.pipe(
  ofType(actions.exportToSVG),
  mergeMap(() => {
    const outerBounds = state$.value.map.outerBounds

    const bbox = [
      outerBounds[1][1], // south
      outerBounds[0][0], // west
      outerBounds[0][1], // north
      outerBounds[1][0], // east
    ].join(',')

    console.log('Export SVG for bounds: ', [
      `${outerBounds[0][1]},${outerBounds[0][0]},#333,circle,"NW"`,
      `${outerBounds[1][1]},${outerBounds[0][0]},#333,circle,"SW"`,
      `${outerBounds[1][1]},${outerBounds[1][0]},#333,circle,"SE"`,
      `${outerBounds[0][1]},${outerBounds[1][0]},#333,circle,"NE"`,
    ].join('\n'))

    // const m = state$.value.map
    // console.log(JSON.stringify({
    //   bounds: m.bounds,
    //   width: m.options.width * 72,
    //   height: m.options.height * 72,
    //   bearing: m.bearing[0],
    //   outerBounds: m.outerBounds,
    //   outerWidth: m.computed.outerWidth,
    //   outerHeight: m.computed.outerHeight,
    //   options: {
    //     cornerRadius: m.options.cornerRadius,
    //     edgeWidth: m.options.edgeWidth,
    //   },
    //   layers: [
    //     { name: 'roads-major', query: OSMQueries.majorRoads('{{bbox}}').trim() },
    //     { name: 'roads-minor', query: OSMQueries.minorRoads('{{bbox}}').trim() },
    //     { name: 'roads-residential', query: OSMQueries.residentialRoads('{{bbox}}').trim() },
    //     { name: 'roads-residential', query: OSMQueries.residentialRoads('{{bbox}}').trim() },
    //     { name: 'water', query: OSMQueries.water('{{bbox}}').trim() },
    //     { name: 'land', query: '' },
    //   ]
    // }))

    const fetchAllFeatures = async () => {
      await api.waitForAvailableSlot()
      const majorRoads = await api.fetchGeoJsonLayer(OSMQueries.majorRoads(bbox))
      await api.waitForAvailableSlot()
      const minorRoads = await api.fetchGeoJsonLayer(OSMQueries.minorRoads(bbox))
      await api.waitForAvailableSlot()
      const residentialRoads = await api.fetchGeoJsonLayer(OSMQueries.residentialRoads(bbox))
      await api.waitForAvailableSlot()
      // const pedestrianRoads = await api.fetchGeoJsonLayer(OSMQueries.pedestrianRoads(bbox))
      // await api.waitForAvailableSlot()
      // const footways = await api.fetchGeoJsonLayer(OSMQueries.footways(bbox))
      // await api.waitForAvailableSlot()
      // const footBridges = await api.fetchGeoJsonLayer(OSMQueries.footBridges(bbox))
      await api.waitForAvailableSlot()
      const water = await api.fetchGeoJsonLayer(OSMQueries.water(bbox))
      // await api.waitForAvailableSlot()
      // const parks = await api.fetchGeoJsonLayer(OSMQueries.parks(bbox))
      // await api.waitForAvailableSlot()
      // const buildings = await api.fetchGeoJsonLayer(OSMQueries.buildings(bbox))
      // await api.waitForAvailableSlot()
      // const piers = await api.fetchGeoJsonLayer(OSMQueries.piers(bbox))

      // TODO handle bbox overlapping multiple grid squares
      const y1 = Math.floor(outerBounds[1][1])
      const y2 = Math.floor(outerBounds[0][1])
      const y = y1 !== y2 ? [y1, y2] : [y1]

      const x1 = Math.floor(outerBounds[0][0])
      const x2 = Math.floor(outerBounds[1][0])
      const x = x1 !== x2 ? [x1, x2] : [x1]

      const land = []
      for (let i = 0; i < y.length; i++) {
        for (let j = 0; j < x.length; j++) {
          land.push(...await api.fetchLandGeoJson({ x: x[j], y: y[i] }))
        }
      }

      return {
        majorRoads,
        minorRoads,
        residentialRoads,
        // pedestrianRoads,
        // footBridges,
        water,
        // parks,
        // footways,
        // buildings,
        land,
        // piers,
        // border: GEORGIA_TECH,
      }
    }

    return from(fetchAllFeatures())
  }),
  map((layers) => {
    const geoJsonSvg = exportLayersToSVG({
      outerBounds: state$.value.map.outerBounds,
      bearing: state$.value.map.bearing[0],
      outerWidth: state$.value.map.computed.outerWidth,
      outerHeight: state$.value.map.computed.outerHeight,
      outerOffsetX: state$.value.map.computed.outerOffsetX,
      outerOffsetY: state$.value.map.computed.outerOffsetY,
    }, layers)

    console.time('Render SVG')
    const outputSvg = render({
      bearing: state$.value.map.bearing[0],
      offsetX: state$.value.map.computed.outerOffsetX,
      offsetY: state$.value.map.computed.outerOffsetY,
      frameOpts: {
        outerWidth: state$.value.map.computed.outerWidth,
        outerHeight: state$.value.map.computed.outerHeight,
        width: state$.value.map.options.width * 72,
        height: state$.value.map.options.height * 72,
        edgeThickness: state$.value.map.options.borderWidth * 72,
        frameThickness: 8,
        holeRadius: 4.5,
        capRadius: 7,
      },
      inputSvg: geoJsonSvg,
    })

    console.timeEnd('Render SVG')
    console.log('DONE!', Humanize.fileSize(outputSvg.length))
    return actions.exportToSVGComplete(outputSvg)
  }),
)

const exportLayersToSVG = ({
  outerBounds,
  bearing,
  outerWidth,
  outerHeight,
  outerOffsetX,
  outerOffsetY,
}, {
  majorRoads,
  minorRoads,
  residentialRoads,
  pedestrianRoads,
  footBridges,
  footways,
  water,
  parks,
  buildings,
  land,
  piers,
  streams,
  border,
}) => {
  const proj = proj4('WGS84', 'GOOGLE')

  const [left, top] = proj.forward(outerBounds[0])
  const [right, bottom] = proj.forward(outerBounds[1])
  const converter = geojson2svg({
    viewportSize: {
      width: outerWidth,
      height: outerHeight,
    },
    mapExtent: {
      left,
      top,
      bottom,
      right,
    },
  })

  const reprojectAndConvert = (collection) => {
    if (!collection) {
      return []
    }

    let features
    if (!(collection instanceof Array)) {
      features = collection.features
    } else {
      features = collection
    }

    return features
      .map(geojson => reproject(geojson, 'WGS84', 'GOOGLE', proj4.defs))
      .flatMap(geojson => converter.convert(geojson))
  }

  const majorRoadStr = reprojectAndConvert(majorRoads)
  const minorRoadStr = reprojectAndConvert(minorRoads)
  const residentialRoadStr = reprojectAndConvert(residentialRoads)
  const pedestrianRoadStr = reprojectAndConvert(pedestrianRoads)
  const footBridgeStr = reprojectAndConvert(footBridges)
  const footwaysStr = reprojectAndConvert(footways)
  const waterStr = reprojectAndConvert(water)
  const parkStr = reprojectAndConvert(parks)
  const buildingStr = reprojectAndConvert(buildings)
  const landStr = reprojectAndConvert(land)
  const piersStr = reprojectAndConvert(piers)
  const streamsStr = reprojectAndConvert(streams)
  const borderStr = reprojectAndConvert(border)

  return `<svg xmlns="http://www.w3.org/2000/svg">
  <!-- translate(${-outerOffsetX}px ${-outerOffsetY}px) rotate(${bearing}) -->
  <g id="land" fill="#ddd" stroke="none" >${landStr.join('\n')}</g>
  <g id="piers" fill="#ddd" stroke="none" >${piersStr.join('\n')}</g>
  <g id="water" fill="#00f" stroke="none">${waterStr.join('\n')}</g>
  <g id="streams" fill="none" stroke="#00f">${streamsStr.join('\n')}</g>
  <g id="buildings" fill="none" stroke="#bbb" stroke-width="0.5">${buildingStr.join('\n')}</g>
  <g id="parks" fill="#0f0" stroke="none">${parkStr.join('\n')}</g>
  <g id="footways" fill="none" stroke="#fff" stroke-width="2">${footwaysStr.join('\n')}</g>
  <g id="bridges-footways" fill="none" stroke="#666" stroke-width="2">${footBridgeStr.join('\n')}</g>
  <g id="roads-pedestrian" fill="#333" stroke="none">${pedestrianRoadStr.join('\n')}</g>
  <g id="roads-residential" fill="none" stroke="#333" stroke-width="0.5">${residentialRoadStr.join('\n')}</g>
  <g id="roads-minor" fill="none" stroke="#fcc" stroke-width="3">${minorRoadStr.join('\n')}</g>
  <g id="roads-major" fill="none" stroke="#f00" stroke-width="3">${majorRoadStr.join('\n')}</g>
  <g id="border" fill="none" stroke="#999" stroke-width="6">${borderStr.join('\n')}</g>
</svg>`
}

export default combineEpics(
  updateBoundsEpic,
  exportEpic
)